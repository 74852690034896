.container {
  composes: paper from '../../App.module.css';
  padding: 0;
}

.feature {
  padding: 20px;
}

.response {
  margin-top: 20px;
}

.form {
  display: inline-block;
}

.identifiers {
  margin-top: 5px;
}

.submit {
  margin-top: 20px;
}

.flag {
  font-weight: bold;
}

.input {
  margin-bottom: 16px !important;
}

.deprecated {
  text-decoration-line: line-through;
}
