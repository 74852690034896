.container {
  display: flex;
  align-items: center;
}

.entityId {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 28px !important;
  display: flex;
}

.entityId > span {
  margin-left: 0.5ch;
}

.badgesAndActions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;
}
