.search {
  composes: paper from '../../App.module.css';
  display: inline-block;
  margin-top: 10px;
}

div.textField {
  margin: 0 12px 10px;
  vertical-align: bottom;
  width: 256px;
}

.searchIcon {
  margin-right: 15px;
  margin-bottom: -5px;
}

button.searchButton {
  margin-bottom: 8px;
  margin-left: 15px;
}
