.label {
  display: inline-block;
  border-radius: 3px;
  padding: 0 9px;
  margin: 2px 5px 2px 0;
  font-size: 11px;
  font-weight: normal;
  width: fit-content;
  align-self: center;
}

.uppercase {
  text-transform: uppercase;
}
