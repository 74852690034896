.badgeWrapper {
  width: fit-content;
  text-align: center;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 8px;
}

.badgeWrapper img {
  width: 24px;
  height: 24px;
}
