.deductionItem + .deductionItem {
  margin-top: 5px;
}

.discountName {
  font-size: 0.835em;
}

.discountValue {
  margin: 0;
}
