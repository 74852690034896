.resetButton a {
  color: white;
}

.detailBlock {
  display: flex;
}

.detailList {
  flex-grow: 1;
}

.updatedAlert {
  background: mediumseagreen;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border-radius: 2px;
  padding: 1px 4px;
  vertical-align: middle;
}

.actionsButton {
  max-height: 40px;
}

.mfaConfirmation {
  display: flex;
  flex-direction: column;
  border: 3px solid var(--red);
  width: 400px;
  padding: 5px 10px 15px;
  margin: 10px 0;
}

.mfaConfirmationConfirmButton {
  background-color: var(--red) !important;
  margin: 20px 0 10px 0;
}
