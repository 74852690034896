.actionsButton {
  margin-top: 15px;
  margin-bottom: 5px;
}

.displayAccount {
  margin-bottom: 18px;
  padding-bottom: 5px;
}

.accordion {
  overflow-y: auto;
}

.cellContentWithTooltip {
  display: flex;
  align-items: center;
  gap: 5px;
}

.discountsTooltipAnchor {
  color: white;
}

.accountID .idNumber {
  font-size: 1.6em;
}

.bb {
  color: var(--green);
  font-weight: bold;
}

.badge {
  float: right;
}

.removePaymentDetailsButton {
  border: none;
  color: #617d8b;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
  font-size: inherit;
}

.removePaymentDetailsButton svg {
  vertical-align: sub;
}

.urlLink {
  transform: rotate(-45deg) translate(2px, -3px);
  cursor: pointer;
}

/* Media Queries */

@media (max-width: 1240px) {
  .displayAccount {
    width: 66%;
    display: flex;
    justify-content: space-between;
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
  }

  .accountHeader,
  .accountDetails {
    width: 50%;
    border-right: 1px solid #eee;
    padding-right: 30px;
  }

  .accountDetails {
    padding: 0 30px;
  }
}
