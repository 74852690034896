.copy {
  margin-top: 0;
}

.bold {
  font-weight: bold;
  margin-top: 24px;
}

.options {
  margin-bottom: 24px;
}

.option {
  display: flex;
  align-items: center;
}
