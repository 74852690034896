.wrapper {
  margin: 40px 20px;
}

.loadingSpinner {
  margin-left: 16px;
}

.locked {
  background-color: #ffdede;
}

.chip {
  margin-left: 5px;
  height: 22px;
}

.avatar {
  background-color: #ffb2b2;
}
