.link {
  color: #324fbe;
  display: flex;
  align-items: center;
}

.link svg {
  margin-left: 0.25ch;
  font-size: 16px;
}
