.header {
  box-sizing: border-box;
  position: fixed !important;
  height: 64px;
  display: flex;
  padding: 0 24px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  width: 100%;
  align-items: center;
  background-color: #fff;
  z-index: 2;
}

.logo {
  height: 25px;
  vertical-align: bottom;
}

.environment {
  color: var(--teal-grey);
  margin-left: 10px;
  position: relative;
  top: 2px;
  text-transform: capitalize;
}

.environment::before {
  content: '/';
  display: inline-block;
  margin-right: 10px;
}

.header button {
  margin-left: auto !important;
}

.link {
  color: #666;
  margin-left: 10px;
  margin-top: 5px;
  display: inline-block;
  transform: rotate(-45deg) translate(0px, -2px);
  cursor: pointer;
}
