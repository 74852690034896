.wrapper {
  margin: 40px 20px;
}

.reportList {
  padding: 20px;
}

.dataSet:not(:last-of-type) {
  padding-bottom: 20px;
  border-bottom: 1px #ddd solid;
}

.reportList dt {
  font-size: 1.17em;
  margin: 1em 0;
}

.reportList dd,
.reportDescription {
  font-style: italic;
  font-weight: 300;
}

.reportList dd {
  margin: 0;
}

dl.reportList {
  margin-top: 0;
}

.error {
  color: #f44336;
}

.formWrapper {
  margin-bottom: 20px;
}

.form {
  padding: 20px;
}

.formFields {
  display: grid;
  grid-template-columns: auto auto 1fr;
  align-items: baseline;
  max-width: 600px;
}

.fieldName {
  font-weight: bold;
}

.fieldOperator {
  padding: 20px;
}

.buttonRow {
  text-align: right;
}

.dataTable {
  border: 1px solid rgba(224, 224, 224, 1);
}

.dataTable th {
  min-width: 150px;
  text-transform: capitalize;
}

.dataTable th,
.dataTable td {
  border: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;
}

.warning {
  margin-top: 20px;
  font-style: italic;
}
