.subscription {
  font-size: 14px;
  line-height: 1.43;
}

.subscription > * + * {
  margin-top: 4px;
  margin-bottom: 0px;
}

.subscriptionTitle {
  margin: 0 0 12px;
  font-size: inherit;
  font-weight: 600;
}

.service {
  text-transform: capitalize;
}

.costs {
  display: flex;
  align-items: baseline;
  gap: 0.75ch;
}

.costs p {
  margin: 0;
}

.discount,
.costBeforeDiscount,
.noDiscount {
  font-size: 12px;
  line-height: 1.333;
}

.noDiscount,
.costBeforeDiscount {
  color: rgba(0, 0, 0, 0.54);
}

.costBeforeDiscount {
  text-decoration: line-through;
}

.discount {
  margin-left: 0;
  padding: 0;
  list-style: none;
  color: #23a68e;
}

.discount li + li {
  margin-top: 4px;
}
