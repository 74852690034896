.form {
  display: flex;
  align-items: center;
}

.form > div {
  margin-right: 15px;
}

.textField {
  margin: 0 12px;
  vertical-align: bottom;
  border-left: 1px solid #eee;
  padding-left: 12px;
  width: 256px;
}

.select {
  vertical-align: bottom;
  width: 256px;
}

.searchIcon {
  margin-right: 15px;
  margin-bottom: -5px;
}

.searchButton {
  margin-left: 10px;
  align-self: flex-end;
}
