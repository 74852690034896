@font-face {
  font-family: 'Ambit';
  src: url('../../fonts/Ambit-SemiBold.woff2') format('woff2'),
    url('../../fonts/Ambit-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Ambit';
  src: url('../../fonts/Ambit-Regular.woff2') format('woff2'),
    url('../../fonts/Ambit-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
}

body.learn-linker {
  background: white;
  font-family: 'Roboto', Helvetica, sans-serif;
}

.learnLinkWrapper h1,
.learnLinkWrapper h2,
.learnLinkWrapper h3,
.learnLinkWrapper h4,
.learnLinkWrapper h5 {
  font-family: 'Ambit', 'Roboto', Helvetica, sans-serif;
}

body.learn-linker div[class^='MainAppBar_header__'] {
  display: none;
}

body.learn-linker div[class^='MainAppBar_header__'] {
  position: relative;
  top: initial;
}

body.learn-linker div[class^='AppContainer_container__-0HJF'] {
  margin: auto;
  width: auto;
}

body.learn-linker div[class^='App_container__'] {
  max-width: none;
}

.learnLinkWrapper .backToAdm {
  display: block;
  margin-bottom: 30px;
  text-decoration: none;
}

.learnLinkWrapper h1 {
  font-size: 50px;
  margin: 0;
}

.learnLinkWrapper h2 {
  font-size: 35px;
  margin: 0 0 30px;
}

.learnLinkWrapper p {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 50px;
  font-family: 'Ambit', Helvetica, sans-serif;
  font-size: 24px;
}

.learnLinkWrapper {
  padding: 0 50px 100px;
  max-width: 1200px;
  margin: 0 auto;
  line-height: 1.5;
}

.learnLinkWrapper ul {
  list-style: none;
  overflow: hidden;
  padding: 0;
}

.listHead,
.listItem {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: 20px auto 50px;
  gap: 15px;
  align-items: center;
}

.listHead {
  grid-template-columns: auto 50px;
  cursor: pointer;
}

.bordered {
  border-bottom: solid 2px black;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.closed + ul {
  height: 0;
}

.learnLinkWrapper .searching .closed + ul {
  height: auto;
}

.listItem label > * {
  cursor: pointer;
  display: inline-block;
}

.listItem a {
  text-decoration: none;
  font-size: 12px;
  margin-left: 10px;
}

.listHead button {
  justify-self: flex-end;
  font-size: 20px;
  align-self: center;
  padding: 6px;
  line-height: 1;
  border: none;
  background: transparent;
  cursor: pointer;
}

.learnLinkWrapper:not(.searching) ul li {
  margin-bottom: 15px;
}

.learnLinkWrapper .selectedList {
  padding: 0 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.learnLinkWrapper .footer.closed {
  height: 80px;
}

.learnLinkWrapper .footer.open {
  overflow-y: scroll;
}

.learnLinkWrapper .footer {
  height: auto;
  max-height: 500px;
}

.learnLinkWrapper .footer .listItem {
  grid-template-columns: auto 50px;
}

.learnLinkWrapper .footer .listItem label h5 {
  cursor: no-drop;
}

.learnLinkWrapper .footer .buttonWrap {
  padding: 0 50px 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}

.learnLinkWrapper .footer .buttonWrap p {
  margin: 0;
  cursor: pointer;
}

.learnLinkWrapper .footer button,
.linkSuccessBox > button {
  background: #324fbe;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: block;
  margin-left: auto;
}

.learnLinkWrapper .footer button:disabled {
  opacity: 40%;
  cursor: not-allowed;
}

.learnLinkWrapper .footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background: white;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 0;
}

.learnLinkWrapper .footer div[class^='ShowSpinner_ShowSpinner__'] {
  padding: 0;
  height: 42px;
}

.learnLinkWrapper h3 {
  margin: 0;
  font-size: 30px;
}

.learnLinkWrapper h4 {
  margin: 0;
  font-size: 24px;
}

.learnLinkWrapper h5 {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.learnLinkWrapper a {
  color: blue;
}

.learnLinkWrapper .successBg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
}

.learnLinkWrapper .linkSuccessBox {
  position: fixed;
  top: 200px;
  margin: 0 auto;
  background: white;
  padding: 40px 30px;
  border-radius: 8px;
  width: 720px;
  left: 50%;
  transform: translate(-50%, 0);
}

.learnLinkWrapper .linkSuccessBox p,
.learnLinkWrapper .linkSuccessBox button {
  font-size: 20px;
}

.learnLinkWrapper input[type='text'] {
  width: 100%;
  font-size: 20px;
  padding: 12px 24px;
  border: #eaeaea solid 2px;
  border-radius: 6px;
  margin-bottom: 30px;
}

.learnLinkWrapper .message h2 {
  margin-bottom: 10px;
}

.learnLinkWrapper .message p {
  margin: 0 0 20px;
}

.learnLinkWrapper .message textarea {
  border: 2px solid #eaeaea;
  border-radius: 6px;
  font-size: 20px;
  margin-bottom: 30px;
  padding: 12px 24px;
  width: 100%;
  height: 150px;
}

.learnLinkWrapper .templates .templateWrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.learnLinkWrapper .templates .template {
  padding: 25px 20px 0;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  cursor: pointer;
}

.learnLinkWrapper .templateWrapper h3 {
  font-size: 20px;
}

.learnLinkWrapper .templateWrapper p {
  border-top: 2px solid #e0e2e8;
  margin: 0;
  font-size: 15px;
  margin-top: 30px;
  padding: 12px 0;
  font-weight: bold;
  color: #5a697c;
}
