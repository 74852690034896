.campaigns h2 {
  margin: 30px 0 0 20px;
}

.createForm {
  composes: paper from '../../App.module.css';
  display: block;
  margin-top: 20px;
}

.campaigns table,
.createForm,
.results {
  max-width: 1300px;
  width: calc(100% - 40px);
}

.campaigns th,
.campaigns td {
  /* Adding important to override MUI theme */
  padding: 4px 15px !important;
}

.results {
  composes: paper from '../../App.module.css';
  display: inline-block;
  padding: 0px;
  margin-bottom: 100px;
}
