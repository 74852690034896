.copyText {
  all: unset;
  outline: revert;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.copyIcon {
  margin-left: 0.5rem;
  font-size: 0.7em;
  color: var(--teal-grey-light);
}

.badge {
  vertical-align: top;
}

.badge span[class*='MuiBadge'] {
  transition: all 0.5s ease;
  background-color: transparent;
  color: white;
  z-index: -1;
}

.badgeSuccess > span[class*='MuiBadge'] {
  background-color: var(--green);
  z-index: 1;
}

.badgeError > span[class*='MuiBadge'] {
  background-color: var(--red);
  z-index: 1;
}
