.title {
  width: 100%;
  display: flex;
  align-items: center;
}

.title span {
  margin-left: auto;
  margin-right: 16px;
}

.detailsWrapper {
  margin-top: 0 !important;
  margin-bottom: 16px !important;
}
