.dialog {
  min-height: 250px !important;
}

.warning {
  background-color: #999;
  color: white;
  width: 75%;
  margin-left: 10%;
  margin-top: 10px;
  padding: 10px;
}

.textField {
  vertical-align: bottom;
  min-width: 320px !important;
  margin: 10px 0 !important;
  display: inline-block;
}
