.siteProduct {
  border: 1px solid #e0e2e8;
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(0, 2fr) 1fr;
}

.siteProduct + .siteProduct {
  margin-top: 12px;
}

.serviceTable,
.subscriptionData {
  padding: 16px 16px 24px;
}
.serviceTable {
  overflow-x: auto;
}

.productHeader {
  display: flex;
  align-items: center;
}

.productName {
  margin: 0 0 0 8px;
  font-size: 14px;
  line-height: 1.428;
  text-transform: capitalize;
}

.subscriptionData {
  border-left: 1px solid #e0e2e8;
}
