.sideMenu > div {
  height: calc(100% - 64px) !important;
  top: 64px !important;
  background-color: var(--teal-grey) !important;
  padding-top: 20px;
  width: var(--sidebar-width) !important;
  display: flex;
  flex-direction: column;
}

.sideMenu a {
  color: #eeeeee !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  letter-spacing: 0.6px;
  padding: 3px 21px;
  line-height: 48px;
  display: block;
  text-decoration: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.sideMenu a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
