.dialogTitle {
  /* Styles copied from the original MUI DialogTitle component */
  margin: 0;
  padding: 16px 24px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}

.icon {
  margin-left: auto !important;
}
