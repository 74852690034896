.form {
  padding: 0 24px;
}

div.inputField {
  width: 300px;
}

div.inputField > div {
  width: 100%;
}

button.createButton {
  margin: 15px 10px;
}
