.columnHead {
  font-size: 0.85em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.65);
}
