.userDetails svg {
  height: 15px !important;
  width: 15px !important;
}

.actionsButton {
  margin-top: 15px;
  margin-bottom: 15px;
}

/* Media Queries */

@media (max-width: 1240px) {
  .userDetails {
    width: 33%;
    padding: 0 0 20px 30px;
  }
}
