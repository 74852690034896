.sitesProperties {
  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
}

.sitesProperties dt {
  font-weight: bold;
}

.sitesProperties dt:after {
  content: ':';
}

.sitesProperties dd {
  margin-left: 0;
}
