:root {
  --teal-grey: #617d8b;
  --teal-grey-light: rgb(160, 177, 187);
  --red: #ea4031;
  --blue: #00a2f2;
  --amber: #f39c11;
  --green: #2cb054;
  --off-white: #f3f3f3;
  --sidebar-width: 225px;
  --scale: #00a2f2;
  --pro: #00a2f2;
  --business: #efb60c;
  --growth: #efb60c;
  --plus: #ab63c7;
  --basic: #8d96a5;
  --free: #8d96a5;
  --cms: #00a2f2;
}

* {
  box-sizing: border-box;
  user-select: auto !important;
}

a {
  color: var(--teal-grey);
  text-decoration: underline;
}

b {
  font-weight: 500;
}

a:hover {
  cursor: pointer;
  color: var(--blue);
}

dt {
  user-select: none !important;
}

:global(.open-in-new) {
  color: inherit !important;
  height: 15px !important;
  vertical-align: text-top !important;
}

a:global(.active) {
  color: #fff !important;
  border-left: 5px solid #fff !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

div:global(.plotly-notifier) {
  visibility: hidden;
}

.paper {
  margin: 0 20px 20px 20px;
  width: calc(100% - 40px);
  padding: 20px;
}

.container {
  position: absolute;
  top: 72px;
  width: 100%;
  max-width: 1800px;
}

:global(.error) {
  color: #f44336;
}

:global(.wrap-columns th),
:global(.wrap-columns td) {
  white-space: normal !important;
  overflow: hidden !important;
  text-overflow: inherit !important;
}

:global(.react-toast-notifications__container) {
  /* This is required to stop the scrollbar appearing in the toast notifications container when it animates. */
  overflow-y: hidden !important;
  /* This is required ensure toasts are above dialog/modal overlays. */
  z-index: 1400 !important;
}
