.error {
  composes: error from global;
  padding: 10px 0 0 1px;
}

.standaloneAddActionButton {
  float: right;
  font-weight: 300;
}

.changesTable {
  font-size: 0.9em;
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
}

.changesTable tr {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.changesTable td,
.changesTable th {
  padding: 5px;
  border-right: 1px solid #ccc;
}

.changesTable thead {
  background: #eee;
}

@keyframes grayFade {
  from {
    background-color: #d0d0d0;
  }
  to {
    background-color: transparent;
  }
}

:global(.newRow) {
  animation: grayFade 5s;
}
