.iconBadge {
  margin: 8px 8px 0;
  background: none;
  border: none;
  padding: 0;
  vertical-align: middle;
  cursor: default;
  font-weight: bold;
}

.iconBadge button {
  cursor: pointer;
}
