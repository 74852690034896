.trialData {
  margin-bottom: 24px;
  margin-left: 24px;
}

.trialDataList {
  margin-top: 0;
}

.trialDataListItem {
  margin: 4px 0;
}

.trialOptionsButton {
  margin-top: 8px;
}
