.datePicker {
  vertical-align: bottom;
  display: block;
  margin-bottom: 16px;
}

.billingCycle {
  display: block;
  margin-bottom: 16px;
}

.datePicker > div,
.billingCycle > div {
  width: 250px;
  margin-bottom: 20px;
}

.formColumn {
  vertical-align: top;
  width: 250px;
  display: inline-block;
}

.formColumn:first-of-type {
  margin-right: 40px;
}
