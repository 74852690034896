.form {
  width: 350px;
  margin: auto;
  padding: 20px;
}

.input {
  width: 100%;
}

form .input {
  margin-bottom: 12px;
}

p.password-error {
  margin: 0;
}

form .button {
  margin-top: 12px;
}
