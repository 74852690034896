.textField {
  vertical-align: bottom;
  margin-right: 20px;
  min-width: 60%;
}

.select {
  vertical-align: bottom;
  min-width: 150px;
  margin-top: 14px;
}

.form {
  margin-top: 20px;
}
