.wrapper {
  margin-top: 0 !important;
  margin-bottom: 16px !important;
}

.wrapper ~ .wrapper {
  margin-top: 16px !important;
}

.link {
  margin-top: 16px;
  margin-bottom: 8px;
}
