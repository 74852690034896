.serviceTable {
  margin: 12px 0 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
}

.service {
  display: contents;
}

.rowContent {
  display: contents;
}

.planAndSource {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}

.planAndSource,
.serviceProperty {
  padding: 8px 12px;
}

.plan {
  font-weight: 600;
}

.statusAndSource {
  margin-top: 2px;
}

.active {
  color: #23a68e;
}

.source {
  font-size: 12px;
  line-height: 1.333;
  color: rgba(0, 0, 0, 0.54);
}

.serviceProperty {
  margin: 0;
}

.activeRow {
  background-color: #f0faf8;
}

.activeRow:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.activeRow:nth-of-type(3) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.servicePeriodsToggle {
  font-size: 14px;
  margin-top: 12px;
}
