.sitesBlock {
  border-top: 1px solid #eee;
  margin-top: 15px;
  font-size: 14px;
  white-space: nowrap;
  cursor: default !important;
  background-color: #fff !important;
}

.sitesBlock > div {
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 10px;
  max-width: 100%;
}

.usersTableContainer {
  width: 100%;
}

.usersTableContainer > div,
.tableWrap > div {
  box-shadow: none !important;
}

.tableWrap > div:last-of-type {
  border-top: 2px solid rgb(224, 224, 224);
  border-radius: 0;
}

.usersTableContainer {
  cursor: initial;
}

.usersBlock {
  background-color: #fff !important;
  padding-bottom: 20px;
}

.usersBlock > div {
  flex-flow: row wrap;
  overflow: auto;
}

.sites {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  padding: 0 !important;
}

.table {
  table-layout: auto !important;
  max-width: 100%;
}

.table > tbody > tr > td {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.icons {
  margin-left: auto;
}

.icons-org {
  margin-right: 5px;
}

.green {
  color: #00c853;
}

.grey {
  color: #ddd;
}

.billingInfo {
  padding-top: 0 !important;
  font-weight: 400;
  width: 100%;
}

.billingInfoSite {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 24px;
}

.billingInfo > div {
  padding: 5px 15px;
  flex: 1;
}

.billingInfo .lockIcons {
  width: 14px !important;
  height: 14px !important;
  color: var(--amber);
}

.billingInfo > div:last-child {
  border-right: none;
}

.filterField {
  height: 30px;
}

.filterTextField {
  top: -30px !important;
}

.infoIcon {
  font-size: 1em;
  margin-left: 5px;
  color: var(--teal-grey);
}

.modifyAccessContainer {
  display: flex;
  align-items: center;
}
