.sitePlan,
.alwaysOn,
.percentageDiscount {
  vertical-align: top;
  margin: 0 15px 15px 0;
}

.sitePlan,
.percentageDiscount {
  margin-bottom: 20px !important;
}

.alwaysOn {
  width: 140px;
}

.percentageDiscount {
  width: 90px;
}

.form {
  margin-top: 15px;
}

.siteSelectTable {
  margin-bottom: 25px;
}

.siteSelectTable div[role='toolbar'] {
  padding-left: 0;
  padding-right: 0;
}
