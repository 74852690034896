.actionsButton {
  padding: 5px 10px !important;
  font-size: 11px;
  border: none;
  border-radius: 2px;
  background-color: var(--teal-grey);
  color: white;
  text-transform: uppercase;
  margin: 0 10px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.actionsButton:hover {
  background-color: var(--teal-grey-light);
}
