.newScreen {
  padding: 40px;
}

.searchBar > div {
  background-color: #f8f9fc;
}

.grid {
  margin-top: -12px;
}

.mainPanel {
  padding: 24px 32px;
}

.mainPanelHeader {
  display: flex;
  align-items: center;
}

.mainPanelHeader button {
  margin-left: auto;
}

.headerDivider {
  margin-top: 12px;
}

.tableDivider {
  margin-top: 32px;
  margin-bottom: 32px;
}

.badges {
  margin-top: 12px;
}

.tabs {
  margin-top: 48px;
}

.noData {
  margin: 2rem;
}
