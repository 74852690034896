.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-top: 40px;
}

.form {
  position: relative;
  padding: 20px 10px 10px;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 30, 0.8);
  border-radius: 4px;
  z-index: 1000;
}

.selects {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.entitySelect {
  flex: 1 3 auto;
}

.featureSelect {
  flex: 3 1 auto;
}

.field {
  margin-bottom: 16px;

  > .MuiFormHelperText-root {
    color: var(--red);
  }
}

.percentage {
  display: flex;
}

.percentageInput {
  margin-left: 20px;
}

.featureName {
  font-family: monospace;
  font-weight: bold;
}
