.username {
  color: #eeeeee !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  letter-spacing: 0.6px;
  padding: 3px 21px;
  line-height: 48px;
  display: block;
  text-decoration: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-top: auto;
}
