.table {
  table-layout: fixed;
}

.table th {
  font-size: 1em !important;
}

.table tr:nth-child(even) {
  background-color: #fafafa;
}

.table tr:nth-child(odd) {
  background-color: #fff;
}

.container {
  padding: 10px;
}
