.container {
  padding: 20px;
  margin: 20px 0;
}

.paper {
  padding: 20px;
  margin-bottom: 20px;
}

.fields {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 0 10px 0;
}

.fields > div {
  margin-right: 15px;
  margin-top: 16px;
}

.button {
  align-self: flex-end;
}

.date,
.select {
  width: 256px;
}
