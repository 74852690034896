.trialOptions {
  gap: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.bold {
  font-weight: bold;
  margin-top: 24px;
}

.textGray {
  color: #696d7f;
}
