.wrapper {
  margin-top: 16px;
  margin-bottom: 28px;
}

.title {
  font-size: 16px;
  line-height: calc(24 / 16);
  font-weight: 600;
}

.entityDetails {
  margin: 0;
  display: grid;
  gap: 12px;
}

.columns1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.columns2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.columns3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.columns4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.detailsItem {
  font-size: 14px;
  line-height: calc(20 / 14);
}

.label,
.value {
  margin: 0;
  padding: 0;
}

.label {
  color: #888;
}

.labelWithTooltip {
  display: flex;
  align-items: flex-start;
}

.value {
  color: #000;
  word-break: break-all;
}

.link {
  color: #324fbe !important;
}

.costInfoIcon {
  margin-left: 6px;
}
