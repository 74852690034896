.form {
  display: flex;
  flex-flow: row wrap;
  margin-top: 10px;
}

div.inputField {
  margin: 0 12px 10px;
  vertical-align: bottom;
  display: block;
  width: 300px;
}

div.inputField > div {
  width: 100%;
}

button.createButton {
  margin: 15px 10px;
}

.checkboxes {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}
