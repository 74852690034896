.searchBox {
  composes: paper from '../../App.module.css';
  display: inline-block;
  margin-top: 20px;
}

.searchResults {
  composes: paper from '../../App.module.css';
  display: inline-block;
  padding: 0;
}

.errorMessage {
  color: red;
  padding-left: 20px;
}

.BounceRecipientsTable {
  font-size: 0.9em;
  border: 1px solid #ccc;
  border-collapse: collapse;
  width: 100%;
}

.BounceRecipientsTable tr {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
}

.BounceRecipientsTable td,
.BounceRecipientsTable th {
  padding: 5px;
  border-right: 1px solid #ccc;
}

.BounceRecipientsTable td:nth-child(1),
.BounceRecipientsTable th:nth-child(1) {
  width: 25%;
}

.BounceRecipientsTable td:nth-child(3),
.BounceRecipientsTable th:nth-child(3) {
  width: 60%;
}

.BounceRecipientsTable thead {
  background: #eee;
}
