.googleButton {
  display: inline-block;
  text-decoration: none;
  background-color: #4285f4;
  border: none;
  color: white;
  padding: 1px;
  width: 230px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
}

.googleButton:hover {
  background-color: #3673d8;
}

.googleButtonIcon {
  vertical-align: middle;
}

.googleButtonText {
  margin-left: 13px;
  vertical-align: middle;
}
