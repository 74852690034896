.formWrapper {
  padding: 20px;
  margin: 20px auto;
  width: 350px;
  display: flex;
  justify-content: center;
}

form .textField {
  margin-bottom: 12px;
  width: 100% !important;
}

form .forgot {
  font-size: 12px;
}

form .button {
  margin-top: 12px;
}

.error {
  composes: error from global;
  display: flex;
  justify-content: center;
}
