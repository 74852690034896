.spinnerWrapper {
  composes: paper from '../../App.module.css';
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBox {
  composes: paper from '../../App.module.css';
  display: inline-block;
  margin-top: 20px;
}

.filterSelect {
  width: 150px;
}

.filterTextField {
  width: 200px;
  margin-right: 16px !important;
}

.lookup {
  composes: paper from '../../App.module.css';
  min-height: 500px;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.executedCommands {
  composes: paper from '../../App.module.css';
  padding: 0;
  margin: 0 20px 20px 20px !important;
}

.accordionSummary > div {
  flex-direction: column;
}

.side,
.main {
  padding: 30px;
  box-sizing: border-box;
}

.main {
  width: calc(100% - 370px);
}

.side {
  width: 370px;
  border-right: 1px solid #f1f1f1;
  font-size: 15px;
}

.side h2,
.side h3 {
  font-size: 17px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.side dt {
  color: #888;
  line-height: 1.6;
  display: inline;
}

.side dd {
  margin: 0;
  display: inline;
  padding-left: 4px;
}

.block_label {
  color: #888;
  line-height: 1.6;
}

.block_value {
  margin: 0;
}

.expandButton {
  height: 100%;
  margin-left: 20px !important;
}

.main {
  display: flex;
  flex-direction: column;
  background-color: var(--off-white);
  flex: 1;
}

.card {
  order: 0;
  color: var(--background-contrast);
}

.cardDeleted {
  composes: card;
  background-color: var(--paper-background-variant);
  order: 1;
  cursor: default;
}

.card:not(:first-of-type) {
  margin-top: 20px;
}

.cardHeader {
  cursor: default !important;
}

.cardHeader svg:hover {
  cursor: pointer;
}

.cardHeader > div {
  padding-right: 10px !important;
  display: flex;
  align-items: center;
}

.cardHeaderTitles {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  padding-right: 10px;
  margin-left: 16px;
}

.cardHeaderTitles dt,
.cardHeaderTitles dd {
  display: inline;
  margin: 0;
}

.cardHeaderTitles dd {
  padding-left: 4px;
}

.cardHeaderMainTitle {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
  display: block;
  margin: 3px 0;
  overflow-wrap: anywhere;
}

.cardHeaderSubtitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  display: block;
  font-weight: 500;
}

.header {
  padding: 15px 0;
}

.filtersWrapper {
  margin: 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.paidFilter {
  align-self: flex-end;
}

.filter {
  display: flex;
  align-items: center;
}

.orgs {
  color: #999;
  display: flex;
  align-items: center;
}

.orgs svg {
  margin-right: 10px;
}

.filterField {
  height: 30px;
  margin-left: auto;
  align-self: flex-end;
}

li[role='menuitem'] a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

li[role='menuitem'] a:hover {
  color: rgba(0, 0, 0, 0.87);
}

/* Media Queries */

@media (max-width: 1240px) {
  .main {
    width: 100%;
  }

  .side {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 0 30px;
  }
}
