.datePickerWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.datePicker {
  margin-right: 20px;
}

.graph {
  height: 100%;
  height: 60vh;
  width: 100%;
}

.table {
  margin-bottom: 20px;
}

.errorMessage,
.infoMessage {
  composes: error from global;
  text-align: center;
}

.infoMessage {
  color: var(--green);
}
